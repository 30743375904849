import { notification } from "antd";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";
import { useState } from "react";
import axiosInstance, {
  axiosSavingInstance,
} from "../../interceptors/axiosInterceptors";
import { useAppContext } from "../../../context/app";

const openSuccessNotification = (res, status) => {
  notification.open({
    message: `${status}`,
    description: `${res}`,
    duration: 8,
    icon: <SmileOutlined style={{ color: "#71b324" }} />,
  });
};

const openErrorNotification = (res, status) => {
  notification.open({
    message: `${status}`,
    description: `${res}`,
    duration: 8,
    icon: <FrownOutlined style={{ color: "red" }} />,
  });
};

const USER_URL = process.env.REACT_APP_USER_SERVICE_PATH;
const SAVING_URL = process.env.REACT_APP_SAVING_SERVICE_PATH;

const SavingsService = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState(null);
  const [link, setLink] = useState(null);
  const [progress, setProgress] = useState({ started: false, percentage: 0 });
  const [isUploadLoading, setUploadIsLoading] = useState(false);

  const { setUserType } = useAppContext();
  // const [userType, setUserType] = useState("");
  // const baseUrl = process.env.REACT_APP_SAVINGS_BASE_URL;

  const getAllSavings = async (limit) => {
    try {
      const { data } = await axiosInstance.get(
        `${USER_URL}/customer/all-savings`
      );
      return data;
    } catch (error) {
      return error;
    }
  };

  const getSavingsById = async (id) => {
    try {
      const { data } = await axiosSavingInstance.get(
        `${SAVING_URL}/savings/${id}`
      );

      localStorage.setItem("savingsData", JSON.stringify(data?.data));
      return data;
    } catch (error) {
      return error;
    }
  };

  const getUserCards = async (id) => {
    try {
      const { data } = await axiosInstance.get(`${USER_URL}/card/client/${id}`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getSavingsProduct = async () => {
    try {
      const { data } = await axiosSavingInstance.get(
        `${SAVING_URL}/savings/products`
      );
      return data;
    } catch (error) {
      return error;
    }
  };

  const handleBulkUpload = async (docType, uploadType) => {
    if (!file) {
      setMessage("No file selected");
      return;
    }
    setUploadIsLoading(true);
    try {
      const url = `${SAVING_URL}/bulk/upload`;
      const formdata = new FormData();

      formdata.append("files", file);
      formdata.append("docType", docType);
      formdata.append("uploadType", uploadType);

      setMessage("Uploading...");
      setProgress((prevState) => ({ ...prevState, started: true }));
      const { data } = await axiosSavingInstance.post(url, formdata, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress((prevState) => ({
            ...prevState,
            percentage: percentCompleted,
          }));
        },
      });
      setMessage("Uploaded successfully");
      const status = "Success";
      const message = data.responseDto.message;
      openSuccessNotification(message, status);
      setUploadIsLoading(false);
      return data;
    } catch (error) {
      const message = error?.response?.data?.responseDto.message;
      openErrorNotification(message, "ERROR");
      return error;
    }
  };

  const downloadBulkTemplate = async (bulkType) => {
    try {
      const url = `${SAVING_URL}/bulk/template/${bulkType}`;
      const { data } = await axiosSavingInstance.get(url);
      if (data?.responseDto?.code === "cv00") {
        // openSuccessNotification(data?.responseDto?.message, "SUCCESS");
        setLink(data?.fileUrl);
        return data?.fileUrl;
      }
    } catch (error) {
      const message = error?.response?.data?.responseDto?.message;
      openErrorNotification(message, "ERROR");
      return error;
    }
  };

  const getAllBulkTransactions = async (pageNo, pageSize) => {
    try {
      const url = `${SAVING_URL}/bulk/all`;
      const { data } = await axiosSavingInstance.post(url, {
        pageNo,
        pageSize,
      });
      if (data?.userType === "INITIATOR") {
        setUserType("INITIATOR");
      } else {
        setUserType("APPROVAL");
      }

      return data;
    } catch (error) {
      return error;
    }
  };

  const getBulkTransactionsByStatus = async (pageNo, pageSize, status) => {
    try {
      const url = `${SAVING_URL}/bulk/allstatus`;
      const { data } = await axiosSavingInstance.post(url, {
        pageNo,
        pageSize,
        value: status,
      });
      return data?.bulkUploadDtoList;
    } catch (error) {
      return error;
    }
  };

  const updateBulkTemplateStatus = async (
    currentState,
    bulkUploadId,
    reject
  ) => {
    try {
      const url = `${SAVING_URL}/bulk/process`;
      const { data } = await axiosSavingInstance.post(url, {
        currentState,
        bulkUploadId,
        reject,
      });
      const status = "Success";
      const message = data?.responseDto?.message;
      openSuccessNotification(message, status);
      return data;
    } catch (error) {
      const status = "Error";
      const message = error?.message;
      openErrorNotification(message, status);
      return error;
    }
  };

  const searchBulkTransactions = async (pageNo, pageSize, value) => {
    try {
      const url = `${SAVING_URL}/bulk/search`;
      const { data } = await axiosSavingInstance.post(url, {
        pageNo,
        pageSize,
        value,
      });
      return data?.bulkUploadDtoList;
    } catch (error) {
      const status = "Error";
      const message = error?.message;
      openErrorNotification(message, status);
      return error;
    }
  };

  const getElectricityToken = async (reference) => {
    try {
      const url = `${SAVING_URL}/wallet/electricity/token/${reference}`;
      const { data } = await axiosSavingInstance.get(url);
      return data?.data?.billsResp?.data;
    } catch (error) {
      const message = error.message;
      openErrorNotification(message, "Error");
    }
  };

  // =========== Savings Product Start ==============
  const getSavingsProducts = async (payload) => {
    try {
      const { data } = await axiosSavingInstance.post(
        `${SAVING_URL}/savings/all/products`,
        payload
      );
      return data?.savingProductsDtoList;
    } catch (error) {
      return error;
    }
  };

  const addSavingsProduct = async (payload) => {
    try {
      const { data } = await axiosInstance.post(
        `${SAVING_URL}/savings/create/product`,
        payload
      );

      if (data?.status === "SUCCESS") {
        openSuccessNotification(data?.data?.resp?.message, data?.status);
        return data;
      } else {
        openErrorNotification(data?.data?.resp?.message, "Error");
      }
    } catch (error) {
      openErrorNotification(
        error?.response?.data?.data?.resp?.message || error.message,
        "Error"
      );
      return error;
    }
  };

  const editSavingsProduct = async (payload) => {
    try {
      const { data } = await axiosInstance.post(
        `${SAVING_URL}/savings/edit/product`,
        payload
      );

      if (data?.status === "SUCCESS") {
        openSuccessNotification(data?.data?.resp?.message, data?.status);
        return data;
      } else {
        openErrorNotification(data?.data?.resp?.message, "Error");
      }
    } catch (error) {
      openErrorNotification(
        error?.response?.data?.data?.resp?.message || error.message,
        "Error"
      );
      return error;
    }
  };

  const createSavingsProductRange = async (payload) => {
    try {
      const { data } = await axiosInstance.post(
        `${SAVING_URL}/savings/create/productrange`,
        payload
      );
      if (data?.status === "SUCCESS") {
        openSuccessNotification(data?.data?.resp?.message, data?.status);
        return data;
      } else {
        openErrorNotification(data?.data?.resp?.message, "Error");
      }
    } catch (error) {
      openErrorNotification(
        error?.response?.data?.data?.resp?.message || error.message,
        "Error"
      );
      return error;
    }
  };

  const updateSavingsProductRange = async (payload) => {
    try {
      const { data } = await axiosInstance.post(
        `${SAVING_URL}/savings/update/product/range`,
        payload
      );
      if (data?.status === "SUCCESS") {
        openSuccessNotification(data?.data?.resp?.message, data?.status);
        return data;
      } else {
        openErrorNotification(data?.data?.resp?.message, "Error");
      }
    } catch (error) {
      openErrorNotification(
        error?.response?.data?.data?.resp?.message || error.message,
        "Error"
      );
      return error;
    }
  };

  const registerProduct = async (payload) => {
    try {
      const { data } = await axiosInstance.post(
        `${SAVING_URL}/savings/update/product`,
        payload
      );
      if (data?.status === "SUCCESS") {
        openSuccessNotification(data?.data?.resp?.message, data?.status);
        return data;
      } else {
        openErrorNotification(data?.data?.resp?.message, "Error");
      }
    } catch (error) {
      openErrorNotification(
        error?.response?.data?.data?.resp?.message || error.message,
        "Error"
      );
      return error;
    }
  };

  const freezeUnfreezeAccount = async (payload) => {
    try {
      const url = `${SAVING_URL}/wallet/nuban/freezeOrunfreeze`;
      const { data } = await axiosInstance.post(url, payload);
      const message = data?.data?.resp?.message;
      if (data?.status?.toUpperCase() === "SUCCESS") {
        openSuccessNotification(message, "Success");
        window.location.reload();
        return data;
      } else {
        openErrorNotification(message, "Error");
        return data;
      }
    } catch (error) {
      openErrorNotification(
        "error",
        error?.response?.data?.data?.resp?.message
      );
      return;
    }
  }
  const allSettlementReport = async (payload) => {
    try {
      const { data } = await axiosInstance.post(
        `${SAVING_URL}/settlementreport/all`,
        payload
      );
      if (data?.responseDto?.code === "cv00") {
        return data;
      } else {
        openErrorNotification(data?.data?.resp?.message, "Error");
      }
    } catch (error) {
      openErrorNotification(
        error?.response?.data?.responseDto?.message || error.message,
        "Error"
      );
      return error;
    }
  };

  const allSettlementReportByDate = async (payload) => {
    try {
      const { data } = await axiosInstance.post(
        `${SAVING_URL}/settlementreport/allbydate`,
        payload
      );
      if (data?.responseDto?.code === "cv00") {
        return data?.settlementReportUploadList;
      } else {
        openErrorNotification(data?.data?.resp?.message, "Error");
      }
    } catch (error) {
      openErrorNotification(
        error?.response?.data?.responseDto?.message || error.message,
        "Error"
      );
      return error;
    }
  };

  const uploadSettlementReport = async (
    file,
    fileName,
    startDate,
    endDate,
    type
  ) => {
    try {
      const myHeaders = new Headers();
      const token = JSON.parse(localStorage.getItem("userDetails"))?.token;
      myHeaders.append("apiKey", token);

      const formdata = new FormData();
      formdata.append("files", file);
      formdata.append("uploadedName", fileName);
      formdata.append("startDate", startDate);
      formdata.append("endDate", endDate);
      formdata.append("settlementType", type);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      const url = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_SAVING_SERVICE_PATH}/settlementreport/upload`;

      await fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result);
          if (result?.responseDto?.code === "cv00") {
            openSuccessNotification(result?.responseDto?.message, "SUCCESS");
            return result;
          }
        })
        .catch((error) => {
          openErrorNotification(error?.message, "Error");
        });
    } catch (error) {
      openErrorNotification(
        error?.responseDto?.message || error.message,
        "Error"
      );
      return error;
    }
  };

  // =========== Savings Product End ==============

  return {
    file,
    message,
    progress,
    link,
    isUploadLoading,
    setLink,
    getAllSavings,
    getSavingsById,
    getSavingsProduct,
    getUserCards,
    handleBulkUpload,
    setFile,
    setProgress,
    downloadBulkTemplate,
    getAllBulkTransactions,
    getBulkTransactionsByStatus,
    updateBulkTemplateStatus,
    searchBulkTransactions,
    getElectricityToken,
    getSavingsProducts,
    addSavingsProduct,
    createSavingsProductRange,
    updateSavingsProductRange,
    editSavingsProduct,
    registerProduct,
    freezeUnfreezeAccount,
    allSettlementReport,
    uploadSettlementReport,
    allSettlementReportByDate,
  };
};

export default SavingsService;
