import axiosInstance from "../../interceptors/axiosInterceptors";
import { notification } from "antd";
import { useState } from "react";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";

const openSuccessNotification = (res, status) => {
  notification.open({
    message: `${status}`,
    description: `${res}`,
    duration: 11,
    icon: <SmileOutlined style={{ color: "#71b324" }} />,
  });
};

const openErrorNotification = (message, status) => {
  notification.open({
    message: `${status}`,
    description: `${message}`,
    duration: 11,
    icon: <FrownOutlined style={{ color: "red" }} />,
  });
};

const USER_URL = process.env.REACT_APP_USER_SERVICE_PATH;
// const BASEURL = process.env.REACT_APP_BASE_URL;

const CustomerService = () => {
  const [messageLoader, setMessageLoader] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [isBvnDetailsLoading, setIsBvnDetailsLoading] = useState(false);
  const [kycTotalElements, setKycTotalElements] = useState(null);
  const [isBvnUpdateDetailsLoading, setIsUpdateBvnDetailsLoading] =
    useState(false);

  const getAllCustomer = async (pageNo, pageSize, sortBy, sortDir) => {
    try {
      const { data } = await axiosInstance.get(
        `${USER_URL}/customer/all?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
      );
      return data;
    } catch (error) {
      return error;
    }
  };

  const searchCustomer = async (params) => {
    try {
      let url = `${USER_URL}/customer/all?`;
      for (var key in params) {
        if (params[key] !== "") {
          url += key + "=" + params[key] + "&";
        }
        if (key === Object.keys(params)[Object.keys(params).length - 1]) {
          url = url.slice(0, -1);
        }
      }
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getCustomerById = async (id) => {
    try {
      const { data } = await axiosInstance.get(`${USER_URL}/customer/${id}`);
      return data;
    } catch (error) {
      return error;
    }
  };

  const setCustomerStatus = async (payload) => {
    try {
      const { data } = await axiosInstance.post(
        `${USER_URL}/status/updatestatus`,
        payload
      );
      const resMessage = data?.message;
      const resStatus = "Success";
      openSuccessNotification(resMessage, resStatus);
      // window.location.reload();
      return data;
    } catch (error) {
      const errMessage = error?.response?.message;
      const errStatus = "Error";
      openErrorNotification(errMessage, errStatus);
      return error;
    }
  };

  const sendCustomerMessage = async (payload) => {
    setMessageLoader(true);
    try {
      const { data } = await axiosInstance.post(
        `${USER_URL}/email/send/customer`,
        payload
      );
      const resMessage = data?.responseMessage;
      const resStatus = "Success";
      setMessageLoader(false);
      openSuccessNotification(resMessage, resStatus);
      return data;
    } catch (error) {
      const errMessage = error?.response?.message;
      const errStatus = "Error";
      setMessageLoader(false);
      openErrorNotification(errMessage, errStatus);
      return error;
    }
  };

  const verifyDocument = async (id, status, reason) => {
    setVerificationStatus(true);
    try {
      const url = `${USER_URL}/doc/status?id=${id}&status=${status}&reason=${reason}`;
      const { data } = await axiosInstance.post(url);
      const resMessage = data?.data?.resp?.message;
      const resStatus = data?.status;
      setVerificationStatus(false);
      openSuccessNotification(resMessage, resStatus);
      return data;
    } catch (error) {
      const errMessage = error?.response?.message;
      const errStatus = "Error";
      setVerificationStatus(false);
      openErrorNotification(errMessage, errStatus);
      return error;
    }
  };

  const getBvnDetails = async (bvn) => {
    setIsBvnDetailsLoading(true);
    try {
      const { data } = await axiosInstance.get(`${USER_URL}/bvn/get/${bvn}`);
      setIsBvnDetailsLoading(false);
      return data;
    } catch (error) {
      setIsBvnDetailsLoading(false);
      return error;
    }
  };

  const updateBvnDetails = async (bvn) => {
    setIsUpdateBvnDetailsLoading(true);
    try {
      const { data } = await axiosInstance.get(`${USER_URL}/bvn/update/${bvn}`);
      setIsUpdateBvnDetailsLoading(false);
      return data;
    } catch (error) {
      setIsUpdateBvnDetailsLoading(false);
      return error.message;
    }
  };

  const getKycByStatus = async (page, pageSize, status) => {
    try {
      const url = `${USER_URL}/doc/list?pageNo=${page}&pageSize=${pageSize}&status=${status}`;
      const { data } = await axiosInstance.get(url);
      setKycTotalElements(data?.data?.totalElements);
      return data.data.documentsDtoList;
    } catch (error) {
      return error;
    }
  };

  const getKycByName = async (page, pageSize, firstName, lastName) => {
    try {
      const url = `${USER_URL}/doc/list?page=${page}&pageSize=${pageSize}&firstName=${firstName}&lastName=${lastName}`;
      const { data } = await axiosInstance.get(url);
      return data.data.documentsDtoList;
    } catch (error) {
      return error;
    }
  };

  const getKycByDate = async (page, pageSize, startDate, endDate, status) => {
    try {
      const url = `${USER_URL}/doc/list?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&status=${status}`;
      const { data } = await axiosInstance.get(url);

      return data?.data?.documentsDtoList;
    } catch (error) {
      return error;
    }
  };

  // ========== Electricity Token Start =============
  const getElectricityToken = async (token) => {
    try {
      const url = `${USER_URL}/wallet/electricity/token/${token}`;
      const { data } = await axiosInstance.get(url);

      if (data?.status === "SUCCESS") {
        openSuccessNotification(data?.data?.resp?.message, "SUCCESS");
        return data?.data?.billsResp;
      } else {
        openErrorNotification(data?.data?.resp?.message, "ERROR");
        return data?.data?.billsResp;
      }
    } catch (error) {
      const message =
        error?.response?.data?.data?.resp?.message | error?.message;
      openErrorNotification(message, "ERROR");
      return error;
    }
  };
  // ========== Electricity Token End =============

  // ========== User Roles and Permission Start =============
  const getAllRoles = async () => {
    try {
      const url = `${USER_URL}/role/all`;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getAllPermissions = async () => {
    try {
      const url = `${USER_URL}/permission/allrequest`;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      return error;
    }
  };

  const createStaff = async (
    payload,
    openSuccess = null,
    closeAddMember = null
  ) => {
    try {
      const url = `${USER_URL}/staff`;
      const { data } = await axiosInstance.post(url, payload);
      if (data?.responseCode === "cv00") {
        openSuccess();
        closeAddMember();
        return data;
      } else {
        openErrorNotification(data?.responseMessage, "ERROR");
      }
    } catch (error) {
      const message = error?.response?.data?.responseMessage | error?.message;
      openErrorNotification(message, "ERROR");
      return error;
    }
  };

  const updateStaff = async (
    payload,
    openSuccess = null,
    closeUpdateMember = null
  ) => {
    try {
      const url = `${USER_URL}/staff/update`;
      const { data } = await axiosInstance.post(url, payload);
      if (data?.responseCode === "cv00") {
        openSuccess();
        closeUpdateMember();
        return data;
      } else {
        openErrorNotification(data?.responseMessage, "ERROR");
      }
    } catch (error) {
      openErrorNotification(error.message, "ERROR");
      return error;
    }
  };

  const assignPermissionToRole = async (payload, openSuccess = null) => {
    try {
      const url = `${USER_URL}/role/update`;
      const { data } = await axiosInstance.post(url, payload);
      if (data?.responseCode === "cv00") {
        openSuccess();
        return data;
      } else {
        openErrorNotification(data?.responseMessage, "ERROR");
      }
    } catch (error) {
      openErrorNotification(error?.response?.data?.responseMessage, "ERROR");
      return error;
    }
  };

  const removePermissionFromRole = async (
    roleId,
    permissionId,
    openSuccess = null
  ) => {
    try {
      const url = `${USER_URL}/role/${roleId}/permissions/${permissionId}/unassign`;
      const { data } = await axiosInstance.post(url);
      if (data?.responseCode === "cv00") {
        openSuccess();
        return data;
      } else {
        openErrorNotification(data?.responseMessage, "ERROR");
      }
    } catch (error) {
      openErrorNotification(error?.response?.data?.responseMessage, "ERROR");
      return error;
    }
  };
  // ========== User Roles and Permission End =============

  // ========== Banner Management Start =============
  const getBannerByStatus = async (status) => {
    try {
      const url = `${USER_URL}/photo/banner?status=${status}`;
      const { data } = await axiosInstance.get(url);
      if (data?.status === "SUCCESS") {
        if (status?.toLowerCase() === "active") {
          localStorage.setItem(
            "activeBanners",
            JSON.stringify(data?.data?.bannerList)
          );
          return data?.data?.bannerList?.reverse();
        } else {
          return data?.data?.bannerList?.reverse();
        }
      } else {
        openErrorNotification(data?.data?.resp?.message, "ERROR");
      }
    } catch (error) {
      const message =
        error?.response?.data?.data?.resp?.message | error?.message;
      openErrorNotification(message, "ERROR");
      return error;
    }
  };

  const deleteBanner = async (id) => {
    try {
      const url = `${USER_URL}/photo/banner/delete/${id}`;
      const { data } = await axiosInstance.get(url);
      if (data?.status === "SUCCESS") {
        openSuccessNotification(data?.data?.resp?.message, "SUCCESS");
      } else {
        openErrorNotification(data?.data?.resp?.message, "ERROR");
      }
    } catch (error) {
      const message =
        error?.response?.data?.data?.resp?.message | error?.message;
      openErrorNotification(message, "ERROR");
      return error;
    }
  };

  const updateBannerStatus = async (status, id, priority) => {
    try {
      const url = `${USER_URL}/photo/banner/update?status=${status}&id=${id}&priority=${priority}`;
      const { data } = await axiosInstance.get(url);
      if (data?.status === "SUCCESS") {
        openSuccessNotification(data?.data?.resp?.message, "SUCCESS");
        return data?.data?.banner;
      } else {
        openErrorNotification(data?.data?.resp?.message, "ERROR");
      }
    } catch (error) {
      const message =
        error?.response?.data?.data?.resp?.message | error?.message;
      openErrorNotification(message, "ERROR");
      return error;
    }
  };

  const createBanner = async (
    fileInput,
    fileName,
    name,
    description,
    ctaUrl,
    priority
  ) => {
    try {
      const url = `${USER_URL}/photo/upload/banner`;
      const formdata = new FormData();
      formdata.append("files", fileInput, fileName);
      formdata.append("name", name);
      formdata.append("description", description);
      formdata.append("ctaUrl", ctaUrl);
      formdata.append("priority", priority);
      const { data } = await axiosInstance.post(url, formdata);
      if (data?.status === "SUCCESS") {
        openSuccessNotification(data?.data?.resp?.message, "SUCCESS");
        return data?.data?.banner;
      } else {
        openErrorNotification(data?.data?.resp?.message, "ERROR");
      }
    } catch (error) {
      openErrorNotification(
        error?.response?.data?.data?.resp?.message,
        "ERROR"
      );
      return error;
    }
  };
  // ========== Banner Management End =============

  const getStaffByEmail = async (email) => {
    try {
      const { data } = await axiosInstance.get(
        `${USER_URL}/staff/view/email/${email}`
      );
      return data?.staffDtoList;
    } catch (error) {
      const errMessage = error?.response?.data?.staffResponse?.responseMessage;
      const resStatus = "Error";
      openErrorNotification(errMessage, resStatus);
      return error;
    }
  };

  return {
    getAllCustomer,
    getCustomerById,
    setCustomerStatus,
    sendCustomerMessage,
    setMessageLoader,
    verifyDocument,
    messageLoader,
    verificationStatus,
    searchCustomer,
    getBvnDetails,
    isBvnDetailsLoading,
    isBvnUpdateDetailsLoading,
    updateBvnDetails,
    getKycByStatus,
    getKycByName,
    getKycByDate,
    createBanner,
    getBannerByStatus,
    updateBannerStatus,
    getAllRoles,
    createStaff,
    getAllPermissions,
    assignPermissionToRole,
    removePermissionFromRole,
    deleteBanner,
    kycTotalElements,
    getElectricityToken,
    getStaffByEmail,
    updateStaff,
  };
};

export default CustomerService;
